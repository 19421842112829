import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidelines"
    }}>{`General guidelines`}</h2>
    <p><em parentName="p">{`Tags`}</em>{` are used for items that need to be labeled, categorized, or organized using keywords that describe them.`}</p>
    <p>{`Multiple or single tags can be used to categorize items.`}</p>
    <p>{`Use tags when content is mapped to multiple categories, and the user needs a way to differentiate between them.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "55.027173913043484%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of tags in a data table.",
        "title": "Example of tags in a data table.",
        "src": "/static/f1ff3d3c12ed8c317e34962fb51bff9d/fb070/tag-usage-2.png",
        "srcSet": ["/static/f1ff3d3c12ed8c317e34962fb51bff9d/d6747/tag-usage-2.png 288w", "/static/f1ff3d3c12ed8c317e34962fb51bff9d/09548/tag-usage-2.png 576w", "/static/f1ff3d3c12ed8c317e34962fb51bff9d/fb070/tag-usage-2.png 1152w", "/static/f1ff3d3c12ed8c317e34962fb51bff9d/c3e47/tag-usage-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p>{`Tags can also be used as a method of filtering data, to show only items within that particular category.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "32.74456521739131%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of tags in search.",
        "title": "Example of tags in search.",
        "src": "/static/1b233b204ae19f5ffa2f207f4d112294/fb070/tag-usage-1.png",
        "srcSet": ["/static/1b233b204ae19f5ffa2f207f4d112294/d6747/tag-usage-1.png 288w", "/static/1b233b204ae19f5ffa2f207f4d112294/09548/tag-usage-1.png 576w", "/static/1b233b204ae19f5ffa2f207f4d112294/fb070/tag-usage-1.png 1152w", "/static/1b233b204ae19f5ffa2f207f4d112294/c3e47/tag-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      